import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '.';


// Should move this logic into advert cards.
const getAdvert = (Advert, i) => Array.isArray(Advert.cards) ? Advert.cards[(i / Advert.spacing + 1) % Advert.cards.length] : Advert.cards

export const Cards = props => {
  const { nodes, Advert } = props;
  const numberOfNodes = nodes.length;
  const lastNodeIndex = nodes.length - 1;

  const extraAdvertSlot = numberOfNodes + 1
  const addExtraAdvert = Advert && extraAdvertSlot % 3 === 0

  return (
    <div className="overflow-hidden">
      <div className="flex flex-wrap -mx-3 xl:-mx-6">
        {nodes.map((item, i) => (
          <>
            {Advert && i !== 0 && i !== lastNodeIndex
              && i % Advert.spacing === 0 && (
                <div
                  className="w-full sm:w-1/2 xl:w-1/3 px-3 xl:px-6 py-6"
                  key={`advert-${i}`}>
                  {getAdvert(Advert, i)}
                </div>
              )}
            <div
              className="w-full sm:w-1/2 xl:w-1/3 px-3 xl:px-6 py-6"
              key={`card_${item.data.slug}`}
            >
              <Card
                {...item.data}
                navigation={{
                  current: i,
                  items: nodes.map(item => `/${item.data.slug}`),
                }}
              />
            </div>
          </>
        ))}
        {
          addExtraAdvert && <div
            className="w-full sm:w-1/2 xl:w-1/3 px-3 xl:px-6 py-6"
            key={`advert-last`}>
            {getAdvert(Advert, numberOfNodes)}
          </div>
        }
      </div>
    </div>
  );
};

Cards.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
