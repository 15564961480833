import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Mail from '../images/Mail.svg';
import Mailbox from '../images/Mailbox.svg';
import Subscribed from '../images/Subscribed.svg';

export const EmailCollection = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        setSubscribed(true);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    !subscribed ?
      <div className="bg-indigo-800 mt-8 flex justify-center items-center h-96 px-6">
        <Mail className="hidden md:block md:w-1/2 lg:w-1/3 h-56 lg:-mt-20" />
        <form onSubmit={handleSubmit} className='w-full lg:w-1/2 xl:w-1/3 flex flex-col justify-center items-left lg:items-center'>
          <div className="text-white py-6 text-center md:text-left lg:text-center">
            <h3 className="text-2xl sm:text-3xl text-secondary mb-2 leading-snug font-bold text-shadow">
              Sign up to our streaming updates!
            </h3>
            <h4 className="block text-base leading-tight font-light text-shadow">
              Be the first to get notified on latest streamings and any new features
            </h4>
          </div>
          <div className='mb-8 flex justify-center md:inline-block'>
            <input
              name="email"
              type="email"
              className="w-64 rounded-l-lg p-4 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white"
              placeholder="your@email.com"
              onChange={handleEmailChange}
            />
            <button className="px-6 -ml-1 rounded-r-lg bg-yellow-400 text-gray-800 font-bold p-4 uppercase border-yellow-500 border-t border-b border-r"
              type="submit">
              Subscribe
            </button>
          </div>
        </form>
        <Mailbox className="hidden lg:block w-1/3 h-56 mt-20" />
      </div> :
      <div className="bg-indigo-800 mt-8 flex justify-center items-center h-96">
        <div className="text-white py-6 text-center">
          <Subscribed className="block h-32 mb-8 -mt-6 -rotate-10 transform" />
          <h3 className="text-2xl sm:text-3xl text-secondary mb-2 leading-snug font-bold text-shadow">
            Thank you for subscribing!
          </h3>
          <h4 className="block text-base leading-tight font-light text-shadow">
            You are all set!
          </h4>
        </div>
      </div>
  )
};

