import React from 'react';
import { format } from 'date-fns';

export const ExpiryBadge = props => {
  const { expiry } = props;

  return (<div
    className="absolute text-sm text-center text-white font-bold p-2 bottom-0 w-full text-shadow"
    style={{ backgroundColor: 'rgba(160,174,192, 0.5)' }}
  >
    Expired on {format(new Date(expiry), 'do MMM yyyy')}
  </div>)
};