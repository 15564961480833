const { utcToZonedTime, format } = require('date-fns-tz')
const { add } = require('date-fns')

export const isPaid = (tags) => tags.includes('Paid');
export const isExpired = (expiry) => Date.parse(new Date()) > Date.parse(expiry);
export const expiryStyle = { filter: 'grayscale(100%)' };

export const getDisplayDate = (input) => {
  const date = new Date(input)
  const timeZone = 'Europe/London'
  return utcToZonedTime(date, timeZone)
}

export const getDisplayDateUTC = (input) => {
  return getDisplayDate(input).toUTCString()
}

export const getDisplayDateOnly = (input) => {
  return format(getDisplayDate(input), 'do MMM yyyy')
}

export const isPassed = (date) => new Date(date) < new Date()

export const isRecent = (date) => new Date(date) < add(new Date(), { days: -7 })

export const toSlug = title => title.trim().split(' ').join('-').split('\'').join('').toLowerCase()

export const getCategoryLink = (category) => `/streamings/${toSlug(category)}`
export const getSeriesLink = (series) => `/series/${toSlug(series)}`

export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}