// import React from 'react';

// export const GoogleAdsHorizontal = ({ slotId }) => (
//   <>
//     <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
//     <ins className="adsbygoogle"
//       style={{ display: 'block' }}
//       data-ad-client="ca-pub-1171219910308676"
//       data-ad-slot={slotId}
//       data-ad-format="auto"
//       data-full-width-responsive="true"></ins>
//     <script dangerouslySetInnerHTML={{
//       __html: `(adsbygoogle = window.adsbygoogle || []).push({});`
//     }}></script>
//   </>
// );

import React, { Component } from 'react';

export class GoogleAds extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins className='adsbygoogle py-4'
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1171219910308676"
        data-ad-slot={this.props.slotId}
        data-ad-format='auto'
        data-full-width-responsive="true"
      >
      </ins>
    );
  }
}
