import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const themeConfig = {
  dark: {
    background: 'bg-indigo-800',
    color: 'text-white text-shadow'
  },
  bright: {
    background: 'bg-grey-100',
    color: 'text-indigo-700'
  }
}

export const Banner = ({ title, description, svg, theme = 'bright' }) => {

  return (
    <div className={`flex justify-center items-center h-96 ${themeConfig[theme].background}`}>
      <div className={`py-6 text-center ${themeConfig[theme].color}`}>
        <Link to="/timeline/streamings/?ref=timeline-banner">
          {svg}
          <h3 className="text-2xl sm:text-3xl text-secondary mb-2 leading-snug font-bold">
            {title}
          </h3>
          <h4 className="block text-base leading-tight font-light">
            {description}
          </h4>
        </Link>
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  svg: PropTypes.object
};
