import React from 'react';
import OnlineMedia from '../images/OnlineMedia.svg';
import PressPlay from '../images/PressPlay.svg';
import MovieNight from '../images/DefaultThumbnail.svg';

export const VectorCollage = props => {
  const defaultSVGclassName =
    'h-auto block bg-gray-100 shadow-all rounded-lg p-8 inline-block absolute';

  return (
    <div className="hidden lg:block w-1/2 absolute md:top-1/4 right-0 max-h-48 h-full items-center mt-12 lg:-mt-8 mr-16 text-center">
      <OnlineMedia
        className={defaultSVGclassName.concat(
          ' w-6/12 top-0 right-0 mr-10 -mt-20'
        )}
      />
      <PressPlay
        className={defaultSVGclassName.concat(' w-5/12 top-0 left-0 ml-12')}
      />
      <MovieNight
        className={defaultSVGclassName.concat(' w-7/12 ml-8 mt-20')}
      />
    </div>
  );
};
