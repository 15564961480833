import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalCard } from './HorizontalCard';

export const Rows = props => {
  const { nodes } = props;

  return (
    <div className="pt-6 overflow-hidden">
      <div className="flex flex-wrap -mx-3 xl:-mx-6">
        {nodes.map((item, i) => (
          <>
            {/* {(i !== 0 && i % 6 === 0) && <AmazonResponsive className="hidden sm:hidden md:hidden lg:hidden xl:block" />}
            {(i !== 0 && i % 4 === 0) && <AmazonResponsive className="sm:block md:block lg:block xl:hidden" />} */}
            <div
              className="w-full sm:w-1/2 xl:w-1/2 px-3 xl:px-6 py-6"
              key={`card_${item.data.slug}`}
            >
              <HorizontalCard {...item.data} />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

Rows.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
