import React from 'react';
import { Link } from 'gatsby';

const TypeButton = ({ title, subtitle, colourScheme, className, link }) => (
  <Link to={link} className={`${className}`}>
    <div className={`relative overflow-hidden bg-${colourScheme}-600 hover:bg-gray-900 rounded-lg shadow-lg h-full items-end`}>
      <div class="absolute inset-0 w-full h-full bg-indigo-500 hover:bg-gray-900 opacity-75"></div>
      <svg className="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none" style={{ transform: 'scale(1.5)', opacity: '0.1' }}>
        <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
        <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
      </svg>
      <div className="relative pt-10 px-10 flex items-center justify-center">
      </div>
      <div className="relative text-white px-4 md:px-6 pb-6 mt-6">
        <span className="hidden md:block opacity-75 -mb-1">{subtitle}</span>
        <span className="block md:hidden opacity-75 -mb-1">Theatre</span>
        <div className="flex justify-between">
          <span className="block font-semibold text-xl">{title}</span>
        </div>
      </div>
    </div>
  </Link>
)

export const TypeButtons = ({ title }) => {
  return (
    <>
      <h2 className="font-semibold text-2xl text-indigo-900 pt-8">
        {title}
      </h2>
      <div class="py-4 flex flex-wrap items-stretch justify-center">
        <TypeButton
          title="Books"
          subtitle="Theatre related readings"
          link="/books"
          colourScheme="blue"
          className="w-1/2 lg:w-1/5 p-2"
        />
        <TypeButton
          title="Films"
          subtitle="Theatre related movies and recordings"
          link="/platforms/amazon"
          colourScheme=""
          className="w-1/2 lg:w-1/5 p-2"
        />
        <TypeButton
          title="Blog"
          subtitle="What's new on The Shows Must Go Online"
          link="/blog"
          colourScheme="yellow"
          className="w-1/2 lg:w-1/5 p-2"
        />
        <TypeButton
          title="Streamings"
          subtitle="All Online Streamings"
          link="/streamings"
          colourScheme="red"
          className="w-1/2 lg:w-1/5 p-2"
        />
        </div>
        <div className="hidden bg-blue-600 bg-yellow-600 bg-red-600"></div>
    </>
  );
};
