import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { toSlug } from '../helper';

const Hashtag = ({ isLink, prefix, item, className, ...props }) => {
  return isLink ? (
    <Link
      to={`${prefix}${toSlug(item)}/`}
      {...props}
      className={`${className} hover:bg-indigo-200`}
    >
      #{item}
    </Link>
  ) : (
    <span {...props} className={className}>
      #{item}
    </span>
  );
};

export const Tags = ({ label, value, isLink, prefix }) => {
  if (typeof value === 'string') {
    value = <div className="truncate">{value}</div>;
  } else if (Array.isArray(value)) {
    value = value.map((item, i) => (
      <Hashtag
        key={`${label}_${i}`}
        className="inline-block bg-indigo-100 rounded-full px-3 py-1 text-sm font-semibold text-indigo-700 mr-2"
        isLink={isLink}
        prefix={prefix}
        item={item}
      />
    ));
  }

  if (value.length === 0) return <></>;

  return (
    <>
      {
        <h4 className="text-indigo-800 uppercase text-xxs tracking-wide font-medium pb-px">
          {label}
        </h4>
      }

      {<div className={`text-base leading-loose mb-4 mt-2`}>{value}</div>}
    </>
  );
};

Tags.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
