import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const BuyMeACoffeePlug = () => (
  <div className="w-full lg:w-auto pt-6 lg:pt-0 text-indigo-800 text-sm">
    Made with <FaHeart className="inline" /> Enjoying the content?{' '}
    <OutboundLink
      className="font-semibold underline"
      href="https://www.buymeacoffee.com/RyKfZ7k"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      Buy me a coffee!
    </OutboundLink>
  </div>
);
