import React from 'react';

export const FromTo = ({ label, value }) => (
  <div className="w-1/2 py-2 border-b">
    <h4 className="text-indigo-800 uppercase text-xxs tracking-wide font-medium pb-px">
      {label}
    </h4>

    <div
      className={`font-medium tracking-wide text-indigo-600 text-xs leading-normal`}
    >
      {value}
    </div>
  </div>
)