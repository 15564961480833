import React from 'react';
import { Link } from 'gatsby';

export const ArticleCard = ({ node }) => {
  console.log(node);
  const {
    title,
    slug,
    featureImage: {
      url,
      //fluid
    },
    contentNode: {
      childMarkdownRemark: {
        excerpt
      }
    }
  } = node;

  return (
    <Link to={`/blog/${slug}/`}>
      <div className="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl w-full mx-auto my-4" style={{ minHeight: '19rem' }}>
        <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg" style={{ minHeight: '19rem' }}>
          <img className="absolute inset-0 w-full h-full object-cover object-center" src={url} alt="" />
          {/* <Img
            fluid={fluid}
            alt={title}
            className="absolute inset-0 w-full h-full object-cover object-center"
          /> */}
          <div className="absolute inset-0 w-full h-full bg-indigo-900 opacity-75"></div>
          <div className="absolute inset-0 w-8/12 h-full flex items-center text-2xl mx-auto
                        justify-center text-white text-center uppercase font-bold tracking-wider">
            {title}
          </div>
        </div>
        <div className="w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg">
          <div className="p-12 md:pr-24 md:pl-16 md:py-12">
            <h2 className="font-bold text-indigo-900">
              {title}
            </h2>
            <p className="text-gray-600">
              {excerpt}
            </p>
          </div>
          <svg className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        </div>
      </div>
    </Link>
  )
};
