import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '.';
import { Calendar } from '.';

export const Timeline = props => {
  const { nodes, Advert } = props;

  return (
    <div className="overflow-hidden flex items-center justify-center">
      <div className="flex flex-wrap -mx-3 xl:-mx-6 w-full lg:w-2/5">
        {nodes.map((item, i) => (
          <>
            {Advert && i !== 0 && i % Advert.spacing === 0 && (
              <div className="flex flex-row py-6 sm:pl-16 pr-3"
                key={`advert-${i}`}>
                {Array.isArray(Advert.card) ? Advert.card[(i / Advert.spacing + 1) % Advert.card.length] : Advert.card}
              </div>
            )}
            <div className="flex flex-row py-6 -pl-24">
              <div>
                <Calendar date={new Date(item.data.start)} />
              </div>
              <div
                className="w-full px-3 xl:px-6  self-center"
                key={`card_${item.data.slug}`}
              >
                <Card
                  {...item.data}
                  navigation={{
                    current: i,
                    items: nodes.map(item => `/${item.data.slug}`),
                  }}
                />
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
