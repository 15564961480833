import { Link } from 'gatsby';
import React from 'react';

// const isPartiallyActive = ({ isPartiallyCurrent, href, location }) => {
//   const className = isPartiallyCurrent ?
//     'py-2 px-6 rounded-t-lg shadow-all bg-gray-100 text-indigo-900 bg-gray-100 text-sm font-medium uppercase' :
//     'py-2 px-6 rounded-t-lg text-gray-500 bg-gray-200 text-sm font-medium uppercase';
//   return {className: className};
// }
const isPartiallyActive = ({ isPartiallyCurrent, href, location }) => {
  const commonClass = 'inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-indigo-900 focus:text-indigo-400 rounded-l-full px-6 py-3 font-medium uppercase';
  const activeClass = 'bg-white text-indigo-900 rounded-full';
  const className = isPartiallyCurrent ?
    `${commonClass} ${activeClass}` : commonClass;
  return { className: className };
}

export const TimelineToggle = ({ category }) => {
  const normalPath = `streamings${category ? '/' + category.split(' ').join('-').toLowerCase() : ''}`
  return (
    <div className="flex justify-center lg:justify-start lg:py-2">
      <div class="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex boxShadow-all my-3">
        <Link to={`/${normalPath}/`}
          getProps={isPartiallyActive}>
          Grid View
      </Link>
        <Link to={`/timeline/${normalPath}/`}
          getProps={isPartiallyActive}>
          Timeline View
      </Link>
      </div>
    </div>
  )
};
