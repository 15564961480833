import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby'
import { getCategoryLink, getSeriesLink } from '../../helper';
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const LinksFooter = () => {
  const {
    site: {
      meta: {},
    },
    allAirtable: {
      categories,
      series
    },
  } = useStaticQuery(graphql`
    query LinksFooterQuery {
      site {
        meta: siteMetadata {
          title
          menu {
            name
            link
          }
        }
      }
      allAirtable (filter: { table: { eq: "Streams" } }) {
        categories: distinct(field: data___tags),
        series: distinct(field: data___series)
      }
    }
  `);

  const Column = ({ title, children }) => (
    <div className="p-5 w-56 lg:w-1/5">
      <div className="text-xs uppercase text-gray-500 font-medium h-4">{title}</div>
      {children}
    </div>
  )

  const FooterLink = ({ to, children, label }) => (
    <Link className="my-3 block leading-snug" to={to}>
      {children}
      <SecondaryLabal text={label} />
    </Link>
  )

  const FooterExternalLink = ({ to, children, label }) => (
    <OutboundLink className="my-3 block leading-snug"
      href={to}
      target="_blank"
      rel="noopener noreferrer">
      {children}
      <SecondaryLabal text={label} />
    </OutboundLink>
  )

  const SecondaryLabal = ({ text }) => (
    <span className="text-teal-600 text-xs p-1">{text}</span>
  )

  return (
    <div class="bg-gray-100 container">
      <div class="m-auto text-indigo-900 flex flex-wrap justify-between">
        <Column title="About Us">
          <FooterLink to="/">
            Home
          </FooterLink>
          <FooterLink to="/blog">
            Blog
          </FooterLink>
          <FooterLink to="/affiliate-disclaimer">
            Disclaimer
          </FooterLink>
          <FooterExternalLink to="https://www.buymeacoffee.com/RyKfZ7k">
            Buy me a coffee!
          </FooterExternalLink>
        </Column>
        <Column title="Categories">
          {categories.slice(0, 6).map(category =>
            <FooterLink to={getCategoryLink(category)}>
              {category}
            </FooterLink>
          )}
        </Column>
        <Column title="Category (cont)">
          {categories.slice(6, categories.length).map(category =>
            <FooterLink to={getCategoryLink(category)}>
              {category}
            </FooterLink>
          )}
          <FooterLink to='/platforms/amazon'>
            Amazon Streamings
          </FooterLink>
          <FooterLink to='/books'>
            Books
          </FooterLink>
        </Column>
        <Column title="Streaming Series">
          {series.filter(s => s !== '').slice(0, 8).map(s =>
            <FooterLink to={getSeriesLink(s)}>
              {s}
            </FooterLink>
          )}
        </Column>
        <Column title="Streaming Series (cont)">
          {series.filter(s => s !== '').slice(8, series.length).map(s =>
            <FooterLink to={getSeriesLink(s)}>
              {s}
            </FooterLink>
          )}
        </Column>
        {/* <div class="p-5 w-48 ">
          <div class="text-xs uppercase text-gray-500 font-medium">Support</div>
          <a class="my-3 block" href="/#">Help Center <span class="text-teal-600 text-xs p-1"></span></a><a class="my-3 block" href="/#">Privacy Policy <span class="text-teal-600 text-xs p-1"></span></a><a class="my-3 block" href="/#">Conditions <span class="text-teal-600 text-xs p-1"></span></a>
        </div>
        <div class="p-5 w-48 ">
          <div class="text-xs uppercase text-gray-500 font-medium">Contact us</div>
          <a class="my-3 block" href="/#">XXX XXXX, Floor 4 San Francisco, CA <span class="text-teal-600 text-xs p-1"></span></a><a class="my-3 block" href="/#">contact@company.com <span class="text-teal-600 text-xs p-1"></span></a>
        </div> */}
      </div>
    </div>
  )
}
