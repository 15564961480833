import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby'
import { SearchWidget } from '../'
import DropdownMenu from './DropdownMenu'

export const Navigation = () => {
  const {
    site: {
      meta: { title, menu },
    },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      site {
        meta: siteMetadata {
          title
          menu {
            name
            link
          }
        }
      }
    }
  `);
  const showFullLogo = 'xl'
  const HomeLogo = (
    <>
      <Link to="/?ref=logo" className={`my-2 leading-tight sm:leading-normal text-base sm:text-lg font-semibold tracking-widest
                                        text-indigo-900 uppercase rounded-sm focus:outline-none focus:shadow-outline hidden ${showFullLogo}:block`}>
        {title}
      </Link>
      <Link to="/?ref=logo" className={`${showFullLogo}:hidden px-2 py-2`}>
        <img
          className="md:px-2"
          src={'/TheShowsMustGoOnlineLogo.png'}
          alt={`${title} Logo`}
          width={64}
          height={64} />
      </Link>
    </>
  )

  return (
    <div className="container w-full text-indigo-900 my-2 z-10 relative lg:border-b h-16 overflow-visible">
      <div className="flex flex-col max-w-screen-xl mx-auto justify-between flex-row">
        <div className="flex flex-row items-start text-left justify-between w-full xl:pt-2 h-auto">
          {HomeLogo}
          <SearchWidget className={`mx-2 sm:ml-4 sm:mr-2 pt-tiny lg:max-w-md xl:max-w-md mt-2 xl:mt-0`} />
          <div className={`lg:relative flex-row flex`}>
            <Link
              to="/blog?ref=hero"
              className={`hidden lg:inline-block h-full cursor-pointer text-indigo-900 px-4 py-3 font-semibold leading-tight hover:bg-gray-200 rounded-lg mt-2 xl:mt-0`}>
              Blog
            </Link>
            <DropdownMenu title="Categories" menu={menu} />
            <Link
              to="/platforms/amazon?ref=hero"
              className={`hidden lg:inline-block rounded-lg px-4 py-3 bg-indigo-900 hover:bg-indigo-800 text-base text-white font-semibold leading-tight shadow-md md:ml-4 self-end`}>
              Stream on Amazon Prime
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
