import React from 'react';
import { Card } from '../Card';
import { graphql, useStaticQuery } from 'gatsby';
import { shuffle } from '../../helper'

export const AdvertCards = ( spacing = 5 ) => {
  const {
    allAirtable: {
      nodes: adverts
    },
  } = useStaticQuery(graphql`
    query AdvertCardsQuery {
      allAirtable (filter: { table: { eq: "Adverts" } }) {
        nodes {
          data {
            name
            type
            url
            image {
              ...CardImageFragment
            }
            summary
          }
        }
      }
    }
  `);

  shuffle(adverts);

  return {
    spacing: spacing,
    cards: adverts.map( advert => <Card
      name={advert.data.name}
      summary={advert.data.summary}
      image={advert.data.image}
      externalLink={advert.data.url}
      isAdvert />)
  }
};

