import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { VectorCollage } from './VectorCollage';

export const VectorHero = props => {
  const { description, category, title } = props;
  const defaultTitle = 'The Shows Must Go Online';
  const pageTitle = category ? `${category} Streamings` : defaultTitle

  return (
    <>
      <div className="container lg:pt-2 pb-4 h-auto flex justify-center">

        <div
          className="background bg-fixed w-full h-auto md:h-header h-max-full
                        bg-indigo-100 lg:mt-6 rounded-xl py-6 sm:py-12
                        lg:py-20 px-4 sm:px-6 lg:px-12 flex items-center
                        relative bg-indigo-800"
        >
          <div className="lg:w-1/2 relative text-white">
            <Link to="/?ref=hero">
              <h1 className="text-center md:text-left text-2xl sm:text-3xl text-secondary mb-2 leading-snug font-bold text-shadow">
                {title ? title : pageTitle}
                {category && (
                  <div className="block text-base leading-tight font-light text-shadow">
                    {defaultTitle}
                  </div>
                )}
              </h1>
              <h2 className="text-center md:text-left sm:text-lg lg:pr-12 text-shadow">
                {description}
              </h2>
            </Link>
            <div className="flex flex-col md:flex-row mt-2 items-center md:justify-start">
              <Link
                to="/submit/?ref=hero"
                className="my-1 inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 text-base text-white font-semibold leading-tight shadow-lg">
                Submit a show!
                </Link>
              <Link
                to="/platforms/amazon/?ref=hero"
                className="my-1 sm:inline-block rounded-lg px-4 py-3 bg-white hover:bg-gray-200 text-base text-gray-800 font-semibold leading-tight shadow-md md:ml-4">
                Streamings on Amazon Prime
                </Link>
            </div>
          </div>
          <VectorCollage />
        </div>
      </div>
      {/* <div className="container flex items-center text-center">
        <Link
          to="/submit"
          className="inline-block sm:mt-0 rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 text-base text-white font-semibold leading-tight shadow-lg"
        >
          Submit a show!
        </Link>
        <Link
          to="/platforms/amazon"
          className="inline-block rounded-lg px-4 py-3 bg-white hover:bg-gray-200 text-base text-gray-800 font-semibold leading-tight shadow-md ml-4"
        >
          Streamings on Amazon Prime
        </Link>
      </div> */}
    </>
  );
};

VectorHero.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
