import React from 'react';
import { Link } from 'gatsby'
import Cross from '../../images/Cross.svg';
import Hamburger from '../../images/Hamburger.svg';
import Arrow from '../../images/arrow.svg';

export default class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  render() {
    const active = "flex shadow-2xl"
    const hidden = "hidden"
    const menu = this.props.menu
    const Icon = this.state.dropdownOpen ? Cross : Hamburger
    const hideHamburger = 'lg'

    return (
      <div className={`lg:relative flex-row flex mt-2 xl:mt-0`}>
        <Icon className={`block ${hideHamburger}:hidden cursor-pointer text-indigo-900`} onClick={this.toggle} />
        <nav className="flex flex-col" onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div
            onClick={this.toggle}
            className={`h-full cursor-pointer text-indigo-900 px-4 py-3 font-semibold leading-tight hidden ${hideHamburger}:block hover:bg-gray-200 rounded-lg`}>
            {this.props.title}
            <Arrow className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1
                              ${this.state.dropdownOpen ? 'transform rotate-180' : ''}`} />
          </div>
          <div className={`flex-col absolute top-full overflow-hidden w-full ${hideHamburger}:w-auto
                          bg-gray-100 rounded z-10 text-indigo-900 pt-1 text-sm left-0
                          ${this.state.dropdownOpen ? active : hidden}`} >
            {menu.map(item =>
              <Link className="py-3 pl-4 pr-8 hover:bg-gray-200"
                to={`${item.link}`}>
                {item.name}
              </Link>
            )}
          </div>
        </nav>
      </div>
    );
  }
}
