import React from "react"
import { Link } from "gatsby";

const defaultClassName = `mt-1 text-indigo-900 h-auto w-full bg-gray-200 rounded-lg z-0 flex shadow-xl overflow-hidden`
const Subheading = ({ children }) =>
  <h4 className="px-4 py-2 uppercase text-xs font-semibold">
    {children}
  </h4>

export const SearchResults = ({ query, results }) => (
  <div className={defaultClassName}>
    {results.length ? (
      <div className="w-full">
        <Subheading>{results.length} streaming(s) matched your query</Subheading>
        <ul className="w-full">
          {results.map(result => (
            <Link to={`/${result.slug}`}>
              <li className="px-4 py-3 text-sm w-full hover:bg-gray-300" key={result.slug}>
                {result.title}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    ) : (
        <Subheading>No result found for `{query}`</Subheading>
      )}
  </div>
)
