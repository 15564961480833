import React from 'react';
const { format } = require('date-fns-tz')

export const Calendar = ({ date }) => (
  <div className="block rounded-lg rounded-t overflow-hidden bg-white text-center w-auto shadow-lg">
    <div className="bg-red-600 text-white py-1 font-bold uppercase text-sm leading-none">
      {format(date, 'LLL')}
    </div>
    <div className="border-l border-r leading-snug">
      <span className="text-3xl font-bold">{format(date, 'd')}</span>
    </div>
    <div className="pb-2 px-2 border-l border-r border-b rounded-b flex justify-between leading-none">
      <span className="text-xs font-bold pr-1">{format(date, 'EEE')}</span>
      <span className="text-xs font-bold">{format(date, 'yyyy')}</span>
    </div>
  </div>
);
