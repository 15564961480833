import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

export const SiteMetadata = ({ description, image, title, path }) => {
  const siteUrl = 'https://theshowsmustgo.online';
  const {
    site: {
      siteMetadata: { locale, siteTitle },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          locale
          siteTitle: title
        }
      }
    }
  `);

  const imgUrl = image.startsWith('http') ? image : `${siteUrl}${image}`;

  return (
    <>
      <Helmet
        defer={false}
        defaultTitle={siteTitle}
        title={title}
        titleTemplate={`%s | ${siteTitle}`}
      >
        <html lang={locale} />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} | ${siteTitle}`} />
        <meta property="og:url" content={path ? `${siteUrl}${path}` : siteUrl} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:image" content={imgUrl} />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={image} />
        <meta property="twitter:description" content={description} />
        <meta name="p:domain_verify" content='b1862d4ec188feafb52d585595f94114' />
        { path && <link rel="canonical" href={`${siteUrl}${path.replace(/\/$/, "")}/`} /> }
      </Helmet>
    </>
  );
};

SiteMetadata.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SiteMetadata.defaultProps = {
  image: '/theshowsmustgoonline.png',
  slug: '',
};
