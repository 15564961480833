import React, { useState, useRef } from "react"
import lunr, { Index } from "lunr";
import { graphql, useStaticQuery } from "gatsby";
import { SearchResults } from './SearchResults'
import Search from '../../images/Search.svg';

const SEARCH_QUERY = graphql`
  query SearchIndexQuery {
    LunrIndex
  }
`

export const SearchWidget = ({ className }) => {
  const inputEl = useRef(null)
  const [value, setValue] = useState("")
  const [results, setResults] = useState([])

  const { LunrIndex } = useStaticQuery(SEARCH_QUERY)
  const { store } = LunrIndex
  const index = Index.load(LunrIndex.index)

  const handleChange = e => {
    const query = e.target.value || ""

    setValue(query)

    if (!query.length) {
      setResults([])
    }

    const keywords = query
      .trim()
      .replace(/\*/g, "")
      .toLowerCase()
      .split(/\s+/)

    if (keywords[keywords.length - 1].length < 2) {
      return
    }

    try {
      let andSearch = []
      keywords
        .filter(keyword => keyword.length > 1)
        .forEach((keyword, i) => {
          // per-single-keyword results
          const keywordSearch = index
            .query(function (q) {
              q.term(keyword, {
                editDistance: keyword.length > 5 ? 1 : 0,
              })
              q.term(keyword, {
                wildcard:
                  lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
              })
            })
            .map(({ ref }) => {
              return {
                slug: ref,
                ...store[ref],
              }
            })

          andSearch = i > 0 ?
            andSearch.filter(x =>
              keywordSearch.some(el => el.slug === x.slug)
            )
            : keywordSearch
        })

      setResults(andSearch)
    } catch (error) {
      console.log(error)
    }
  }

  const defaultClass = `transition-colors duration-100 ease-in-out focus:outline-0 border border-transparent
                        focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 pr-4 pl-10
                        block w-full appearance-none leading-normal z-10 text-indigo-900`;

  return (
    <div className={`w-full ${className}`}>
      <div role="search">
        <label htmlFor="search-input" className="hidden">
          Search Streamings by Name
        </label>
        <div className="relative">
          <input
            id="search-input"
            className={defaultClass}
            ref={inputEl}
            type="search"
            value={value}
            onChange={handleChange}
            placeholder="Search for streamings"
          />
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
            <Search />
          </div>
        </div>
        {/* {value && (
          <button
            type="button"
            aria-label="Reset search"
            onClick={e => {
              handleChange(e)
              inputEl.current.focus()
            }}
          >
          </button>
        )} */}
      </div>
      {value.trim().length > 1 && <SearchResults query={value} results={results} />}
    </div>
  )
}
