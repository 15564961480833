import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const streamingPagesRegex = /streamings[\/]*[0-9]*[\/]*$/i

const isPartiallyActive = ({ isPartiallyCurrent, href, location }) => {
  const itemStyle = `p-4 lg:py-2 text-sm font-base bg-transparent
                   rounded-full lg:mt-0 lg:ml-2 hover:text-indigo-900
                   focus:text-indigo-900 hover:bg-gray-300 focus:bg-gray-300
                   focus:outline-none focus:shadow-outline`
  const category = href.split('/');
  const isCategoryMenu = category.length === 3

  const matchesBaseCategory = isPartiallyCurrent
    && (href !== '/streamings/' || location.pathname.match(streamingPagesRegex));
  const matchesTimeline = isCategoryMenu
    && location.pathname.includes('timeline') && location.pathname.includes(category[2])

  return matchesBaseCategory || matchesTimeline
    ? { className: `${itemStyle} text-white bg-indigo-800 font-semibold` } : { className: itemStyle }
}

const NavigationItem = ({ to, children }) => {
  return <Link
    to={`${to}`}
    getProps={isPartiallyActive}>
    {children}
  </Link>
}

export const CategoryMenu = () => {
  const {
    site: {
      meta: { menu },
    },
  } = useStaticQuery(graphql`
    query CategoryMenuQuery {
      site {
        meta: siteMetadata {
          menu {
            name
            link
            type
          }
        }
      }
    }
  `);

  return (<>
    <nav className={`h-auto top-auto relative rounded-lg text-gray-600 my-2 hidden lg:inline-block`}>
      {menu.filter(link => link.type === 'category')
        .map(item =>
          <NavigationItem to={item.link} key={item.link}>
            {item.name}
          </NavigationItem>
        )}
    </nav>
  </>)
};
