import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import DefaultThumbnail from '../images/DefaultThumbnail.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const HorizontalCard = props => {
  const { name, image, summary, url } = props;

  let cover, localFiles;
  if (image) {
    localFiles = image.localFiles;
    cover = localFiles && localFiles[0];
  }

  return (
    <OutboundLink
      href={url}
      className="max-w-sm w-full lg:max-w-full lg:flex h-full"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="lg:h-auto bg-cover lg:w-4/12
                   rounded-lg rounded-b-none lg:rounded-lg lg:rounded-r-none
                   text-center overflow-hidden bg-white p-1
                   flex flex-col justify-center shadow"
        title={name}
      >
        {image && localFiles && cover ? (
          <Img
            fluid={cover.childImageSharp.fluid}
            alt={name}
            className="h-64 max-h-full lg:max-h-screen lg:h-auto"
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
            <DefaultThumbnail />
          )}
      </div>
      <div className="bg-white rounded-lg rounded-t-none lg:rounded-lg lg:rounded-l-none p-4
                      flex flex-col justify-center leading-normal lg:w-8/12 shadow">
        <div className="mb-8">
          <div className="text-indigo-500 font-bold text-xl mb-2">{name}</div>
          <p className="text-indigo-900 text-base">{summary}</p>
        </div>
      </div>
    </OutboundLink>
  );
};

HorizontalCard.propTypes = {
  country: PropTypes.string.isRequired,
  image: PropTypes.shape({
    localFiles: PropTypes.array,
  }).isRequired,
  name: PropTypes.string.isRequired,
  navigation: PropTypes.shape({
    current: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.string),
  }),
  slug: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const query = graphql`
  fragment HorizontalCardImageFragment on AirtableField {
    localFiles {
      childImageSharp {
        fluid(maxWidth: 190, maxHeight: 290, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
