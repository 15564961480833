import React from 'react';
import { Helmet } from 'react-helmet';

const SumoScript = () => {
  // console.log('---------> Rendering Sumo Script <---------');
  return (
    <Helmet defer={true}>
      {typeof window !== 'undefined' && (
        <script async>
          {(function (s, u, m, o, j, v) {
            j = u.createElement(m);
            v = u.getElementsByTagName(m)[0];
            j.async = 1;
            j.src = o;
            j.dataset.sumoSiteId =
              'ab0edbe0ce89cbec46cbbd435c1c1fc1f027e7c23868fe3c7f17d66810a1ce6c';
            v.parentNode.insertBefore(j, v);
          })(window, document, 'script', '//load.sumo.com/')}
        </script>
      )}
    </Helmet>
  );
};

//This is not working :(
export const Sumo = React.memo(SumoScript);
