import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import DefaultThumbnail from '../images/DefaultThumbnail.svg';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { isExpired, expiryStyle, isPaid, getDisplayDateOnly, isPassed } from '../helper';
import { Link } from 'gatsby-plugin-modal-routing';
import PropTypes from 'prop-types';
import React from 'react';
import { Feature } from '.';
import { Tags } from '.';
import { PaidBadge } from '.';
import { ExpiryBadge } from '.';
import { AdvertBar } from '.';
import { FromTo } from '.';

const shouldDisplayDate = (date) => date && !isPassed(date)

const CardLink = ({ children, externalLink, slug, navigation }) => {
  return slug && navigation ? <Link
    to={`/${slug}/`}
    state={{ navigation }}
    className="items-start h-full"
  >
    {children}
  </Link> :
  <OutboundLink href={externalLink}
      className="items-start h-full"
      target="_blank"
      rel="noopener noreferrer">
    {children}
  </OutboundLink>
}

export const Card = props => {
  const {
    country,
    name,
    image,
    externalLink,
    navigation,
    slug,
    tags,
    summary,
    start,
    expiry,
    isAdvert,
  } = props;

  let cover, localFiles;
  if (image) {
    localFiles = image.localFiles;
    cover = localFiles && localFiles[0];
  }

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden hover:bg-indigo-100">
      <CardLink slug={slug} navigation={navigation} externalLink={externalLink}>
        <div className="bg-white relative">
          {image && localFiles && cover ? (
            <Img
              fluid={cover.childImageSharp.fluid}
              alt={name}
              className="absolute"
              imgStyle={isExpired(expiry) ? expiryStyle : undefined}
            />
          ) : (
              <DefaultThumbnail />
            )}
          {tags && isPaid(tags) && <PaidBadge />}
          {isExpired(expiry) && <ExpiryBadge expiry={expiry} />}
          {isAdvert && <AdvertBar />}
        </div>
        <div className="p-5 flex flex-col">
          <div className="w-full">
            <h1 className="text-2xl text-indigo-500 font-bold leading-snug">
              {name}
            </h1>
            <div className="w-full px-1 pt-2 pb-4 flex items-between">
              <FromTo label="From" value={shouldDisplayDate(start) ? getDisplayDateOnly(start) : 'Now'} />
              {expiry ? <FromTo label="To" value={getDisplayDateOnly(expiry)} /> : <FromTo />}
            </div>
            <p className="text-base text-indigo-900 mb-5 font-medium">
              {summary}
            </p>

            {country && <Feature label="Where?" value={country} />}
            {tags && <Tags label="What?" value={tags} />}
          </div>
        </div>
      </CardLink>
    </div>
  );
};

Card.propTypes = {
  country: PropTypes.string.isRequired,
  image: PropTypes.shape({
    localFiles: PropTypes.array,
  }).isRequired,
  name: PropTypes.string.isRequired,
  navigation: PropTypes.shape({
    current: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.string),
  }),
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

Card.defaultProps = {
  navigation: {},
};

export const query = graphql`
  fragment CardImageFragment on AirtableField {
    localFiles {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 330, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
