import React from 'react';
import { isExpired, expiryStyle, isPaid } from '../helper';
import DefaultThumbnail from '../images/DefaultThumbnail.svg';
import Img from 'gatsby-image';
import { ExpiryBadge } from '../components';
import { PaidBadge } from '../components';
import ReactPlayer from 'react-player';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Image = ({ item }) => {
  const {
    name,
    image,
    tags,
    url,
    expiry,
  } = item;

  let cover, localFiles;
  if (image) {
    localFiles = image.localFiles;
    cover = localFiles && localFiles[0];
  }

  return <OutboundLink href={url} target="_blank" rel="noopener noreferrer" className="relative w-full block">
    {image && localFiles && cover ? (
      <Img fluid={cover.childImageSharp.fluid} alt={name} imgStyle={isExpired(expiry) ? expiryStyle : undefined} />
    ) : (
        <DefaultThumbnail />
      )}
    {isPaid(tags) && <PaidBadge />}
    {isExpired(expiry) && <ExpiryBadge expiry={expiry} />}
  </OutboundLink>
}

export const StreamingMedia = ({ item }) => {
  return !isExpired(item.expiry) && ReactPlayer.canPlay(item.url) && !item.url.includes('/user/')  ?
     <ReactPlayer url={item.url} controls={true} width='100%'/> : <Image item={item} />;
};
