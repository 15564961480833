import React from 'react';

export const PaidBadge = props => {
  return (<div
    className="absolute top-0 left-0 bg-yellow-600 text-sm text-white font-medium px-3 py-1 rounded-full m-4 tracking-wide"
    style={{ backgroundColor: 'rgba(214,158,46, 0.7)' }}
  >
    {' '}
    <span role="img" aria-label="bag of money">
      💰
    </span>{' '}
    Paid{' '}
  </div>)
};
