import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { add } from 'date-fns'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export const AddToCalendarButton = ({ className, ...props }) => {
  const icon = { 'calendar': 'left' };
  const handleClick = () => {
    trackCustomEvent({
      category: "Add to Calendar Button",
      action: "Click"
    });
  }

  const { title, description, location, startTime, endTime } = props;
  const event = {
    title: title,
    description: description + ' - This is a calendar reminder created via https://theshowsmustgo.online/',
  location: location
  }

  if (!startTime && !endTime)
    return <></>

  if (startTime && endTime) {
    event.startTime = startTime
    event.endTime = endTime
  }

  else if (startTime && !endTime) {
    event.startTime = startTime
    event.endTime = add(new Date(startTime), { hours: 4 }).toISOString()
  }
  else if (!startTime && endTime) {
    event.title = `[Streaming Expiring] ${title}`
    event.startTime = add(new Date(endTime), { hours: -4 }).toISOString()
    event.endTime = endTime
  }

  return (<div className="inline" onClick={handleClick} onKeyDown={handleClick} role="button">
      <AddToCalendar
        event={event}
        buttonLabel={'Add to My Calendar'}
        buttonTemplate={icon}
        className="fa-calendar"
        buttonWrapperClass={`relative cursor-pointer`}
        buttonClassClosed={`inline-block text-sm rounded-lg px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg ${className}`}
        dropdownClass={'absolute w-full bg-indigo-100 rounded-b-lg text-indigo-800 font-medium text-sm shadow-lg px-4 py-2 add-to-calendar-dropdown'}
      />
    </div>);
}
