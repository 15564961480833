import { Link } from 'gatsby';
import React from 'react';

export const Button = ({ className, children, ...props }) => (
  <Link
    {...props}
    className={`inline-block rounded-lg px-4 py-3 text-white bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2 ${className}`}
  >
    {children}
  </Link>
);
